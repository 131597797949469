import { oidc } from 'services/oidcService';
import { callApi } from './callApi';

export type RedirectsDto = {
    id: string;
    vendorName: string;
    routeFrom: string;
    routeTo: string;
    statusCode: number;
    lastUsed: string | null;
    lastModified: string;
};

export type UpdateRedirectDto = {
    routeFrom: string;
    routeTo: string;
    statusCode: number;
};

export type BulkCreateRedirectDto = {
    id: number;
} & UpdateRedirectDto;

export const VALIDATION_ERROR_TYPE = {
    EXIST: 'redirect-already-exists',
    LOOP: 'redirect-loop',
    CHAIN: 'multiple-redirect-chain',
    SAME_ORIGIN: 'redirect-same-origin',
    BASIC_VALIDATION: 'basic-validation',
} as const;

type BaseRedirectSuggestionDto = {
    type: (typeof VALIDATION_ERROR_TYPE)[keyof typeof VALIDATION_ERROR_TYPE];
    message: string;
};

export type RedirectLocalSuggestionDto = BaseRedirectSuggestionDto & {
    delete?: BulkCreateRedirectDto[];
    create?: BulkCreateRedirectDto[];
};

export type RedirectExecutiveSuggestionDto = BaseRedirectSuggestionDto & {
    delete?: RedirectsDto[];
    create?: UpdateRedirectDto[];
    update?: Array<{ target: RedirectsDto; change: UpdateRedirectDto }>;
};

export type RedirectValidationErrorDto<SRC extends UpdateRedirectDto = UpdateRedirectDto> = {
    src: SRC;
    executiveSuggestions?: RedirectExecutiveSuggestionDto[];
    localSuggestions?: RedirectLocalSuggestionDto[];
};

export type ApplySuggestionResultDto = {
    created: RedirectsDto[];
    updated: RedirectsDto[];
    deleted: RedirectsDto[];
};

export const getVendorRedirects = async (vendorName: string): Promise<RedirectsDto[]> => {
    const token = await oidc.getAccessToken();
    return await callApi('RedirectApiUrl', 'GET', `redirects/${vendorName}`, null, {}, token);
};

export const updateRedirect = async (
    vendorName: string,
    id: string,
    redirect: UpdateRedirectDto,
): Promise<RedirectsDto> => {
    const token = await oidc.getAccessToken();
    return await callApi('RedirectApiUrl', 'PATCH', `redirects/${vendorName}/redirect/${id}`, redirect, {}, token);
};

export const applySuggestion = async (
    vendorName: string,
    suggestion: RedirectExecutiveSuggestionDto,
): Promise<ApplySuggestionResultDto> => {
    const token = await oidc.getAccessToken();
    const BATCH_SIZE = 200;
    const createCount = suggestion.create?.length || 0;
    const deleteCount = suggestion.delete?.length || 0;
    const updateCount = suggestion.update?.length || 0;
    const createLoads = createCount > BATCH_SIZE || false;
    const deleteLoads = deleteCount > BATCH_SIZE || false;
    const updateLoads = updateCount > BATCH_SIZE || false;
    if (createLoads || deleteLoads || updateLoads) {
        const created: RedirectsDto[] = [];
        const updated: RedirectsDto[] = [];
        const deleted: RedirectsDto[] = [];
        if (createCount) {
            for (let i = 0; i < createCount; i += BATCH_SIZE) {
                const chunk = suggestion.create?.slice(i, i + BATCH_SIZE) || [];
                const result = await callApi(
                    'RedirectApiUrl',
                    'PATCH',
                    `redirects/${vendorName}/suggestion`,
                    { ...suggestion, message: '', create: chunk, deleteLoads, delete: [] },
                    {},
                    token,
                );
                created.push(...result.created);
            }
        }
        if (deleteCount) {
            for (let i = 0; i < deleteCount; i += BATCH_SIZE) {
                const chunk = suggestion.delete?.slice(i, i + BATCH_SIZE) || [];
                const result = await callApi(
                    'RedirectApiUrl',
                    'PATCH',
                    `redirects/${vendorName}/suggestion`,
                    { ...suggestion, message: '', delete: chunk, create: [], update: [] },
                    {},
                    token,
                );
                deleted.push(...result.created);
            }
        }
        if (updateCount) {
            for (let i = 0; i < updateCount; i += BATCH_SIZE) {
                const chunk = suggestion.update?.slice(i, i + BATCH_SIZE) || [];
                const result = await callApi(
                    'RedirectApiUrl',
                    'PATCH',
                    `redirects/${vendorName}/suggestion`,
                    { ...suggestion, message: '', update: chunk, create: [], delete: [] },
                    {},
                    token,
                );
                updated.push(...result.created);
            }
        }
        return { created, updated, deleted };
    }
    return await callApi(
        'RedirectApiUrl',
        'PATCH',
        `redirects/${vendorName}/suggestion`,
        { ...suggestion, message: '' },
        {},
        token,
    );
};

export const createRedirect = async (vendorName: string, redirect: UpdateRedirectDto): Promise<RedirectsDto> => {
    const token = await oidc.getAccessToken();
    return await callApi('RedirectApiUrl', 'POST', `redirects/${vendorName}`, redirect, {}, token);
};

export const createBulkRedirects = async (
    vendorName: string,
    redirects: BulkCreateRedirectDto[],
): Promise<RedirectsDto[]> => {
    const token = await oidc.getAccessToken();
    return await callApi('RedirectApiUrl', 'POST', `redirects/${vendorName}/bulk`, redirects, {}, token);
};

export const deleteRedirect = async (vendorName: string, id: string): Promise<void> => {
    const token = await oidc.getAccessToken();
    return await callApi('RedirectApiUrl', 'DELETE', `redirects/${vendorName}/${id}`, null, {}, token);
};
